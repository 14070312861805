.App {
  background-color: var(--dark);
  height: 100vh;
  text-align: center;
}

.nav-logo {
  max-width: 500;
  width: 100%;
  height: auto;
}

.background-container {
  position: relative;
  text-align: center;
}

.background-image img {
  max-width: 1000px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.image-resizable-small {
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

:root {
  --dark: #292929;
  --light: #c9b8af;
}
